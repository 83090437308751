import React from "react";
import Styles from "./CloudPage.module.css";
import { CLOUDDATA } from "../../../Config/Config";
import Card from "../Card/Card";

const CloudPage = () => {
  return (
    <div className={`${Styles?.Container} `}>
      <h1>Cloud provisioning(IaC)</h1>
      <p>
        Infrastructure as Code (IaC) is an approach to managing data center
        server, storage, and networking infrastructure. IaC is meant to
        significantly simplify large-scale configuration and management. With
        traditional data center infrastructure management, every configuration
        change required manual action by operators and system administrators.
        With IaC, infrastructure configuration information is housed in
        standardized files, which can be read by software that maintains the
        state of the infrastructure. IaC can improve productivity and
        reliability because it eliminates manual configuration steps.
      </p>
      <div className={Styles.CloudCardCont}>
        {CLOUDDATA.map((card, index) => (
          <Card
            className={Styles?.CloudCard}
            iconclass={Styles?.icon}
            TitleCont={Styles?.titleCont}
            key={index}
            image={card.icon}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
};

export default CloudPage;
