import React, { useEffect, useRef } from "react";
import Styles from "./Card.module.css";

const Card = ({
  icon,
  title,
  image,
  description,
  className,
  iconclass,
  TitleCont,
  imageClass,
}) => {

  const cardRef = useRef(null);

  useEffect(() => {
    const cardElement = cardRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          cardElement.classList.add(Styles.visible);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (cardElement) {
      observer.observe(cardElement);
    }

    return () => {
      if (cardElement) {
        observer.unobserve(cardElement);
      }
    };
  }, []);
  return (
    <div ref={cardRef} className={`${Styles.card} ${className} bo-shadow`}>
      <div className={`${Styles?.cardTitle} `}>{title}</div>
      <img className={`${Styles?.cardImage} ${imageClass}`} src={image} alt=""/> 
      {/* <div className={`${Styles?.cardIcon} ${iconclass}`}>{icon}</div> */}
      <div className={`${Styles.content} ${TitleCont}`}>
        <p className={Styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default Card;
