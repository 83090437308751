import React from "react";
import Styles from "./Products.module.css";
import CardContainer from "../CardContainer/CardContainer";
import Button from "../../Elements/Button/Button";
const Products = () => {
  return (
    <div className={`${Styles?.Container} `}>
      <h1>Product & Services Offering</h1>
      <CardContainer />
   
    </div>
  );
};

export default Products;
