import React, { useEffect, useRef, useState } from 'react';
import Styles from './SixPackAI.module.css';
import Sixpack from '../../../assets/img/All Tecdemy 6pack ai post (3) 1.png';
import vector1 from '../../../assets/img/Vector 17.png';
import vector2 from '../../../assets/img/Vector 18.png';
import vector3 from '../../../assets/img/Vector 19.png';
import vector4 from '../../../assets/img/Vector 20.png';
import vector5 from '../../../assets/img/Vector 21.png';
import vector6 from '../../../assets/img/Vector 22.png';
import AIimage1 from '../../../assets/img/Frame 1321313995.png';
import AIimage2 from '../../../assets/img/Frame 1321313996.png';
import AIimage3 from '../../../assets/img/Frame 1321313997.png';
import AIimage4 from '../../../assets/img/Frame 1321313998.png';
import AIimage5 from '../../../assets/img/Frame 1321313999.png';
import AIimage6 from '../../../assets/img/Frame 1321314000.png';

const SixPackAI = () => {
  const [visible, setVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`${Styles?.Container}`}
    >
      <h1>6 Packs of AI</h1>
      <div className={`${Styles?.cardcontainer} ${visible ? 'visible' : 'hidden'}`}>
        <div className={`${Styles?.vectorCont} ${visible ? Styles?.animateVector : ''}`}>
          <img src={vector4} className={`${Styles?.vector1} ${visible ? Styles?.animateVector : ''}`} />
          <img src={AIimage1} className={`${Styles?.Image1} ${visible ? Styles?.animateImage : ''}`} />
          <img src={vector5} className={`${Styles?.vector2} ${visible ? Styles?.animateVector : ''}`} />
          <img src={AIimage3} className={`${Styles?.Image2} ${visible ? Styles?.animateImage : ''}`} />
          <img src={vector6} className={`${Styles?.vector3} ${visible ? Styles?.animateVector : ''}`} />
          <img src={AIimage2} className={`${Styles?.Image3} ${visible ? Styles?.animateImage : ''}`} />
        </div>
        <div className={`${Styles?.MainImage} ${visible ? Styles?.animateMainImage : ''}`}>
          <img src={Sixpack} />
        </div>
        <div className={`${Styles?.vectorContRight} ${visible ? Styles?.animateVector : ''}`}>
          <img src={vector1} className={`${Styles?.vector4} ${visible ? Styles?.animateVector : ''}`} />
          <img src={AIimage4} className={`${Styles?.Image4} ${visible ? Styles?.animateImage : ''}`} />
          <img src={vector2} className={`${Styles?.vector5} ${visible ? Styles?.animateVector : ''}`} />
          <img src={AIimage5} className={`${Styles?.Image5} ${visible ? Styles?.animateImage : ''}`} />
          <img src={vector3} className={`${Styles?.vector6} ${visible ? Styles?.animateVector : ''}`} />
          <img src={AIimage6} className={`${Styles?.Image6} ${visible ? Styles?.animateImage : ''}`} />
        </div>
      </div>
      {/* {SIXPACKOFAI.map((card, index) => (
        <Card key={index} icon={card.icon} title={card.title} />
      ))} */}
    </div>
  );
};

export default SixPackAI;
