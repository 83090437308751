import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MENU_LIST } from "../../Config/Config";
import Styles from "./MobileDrawer.module.css";
import { AppContext } from "../Context/Context";
import { clearUserData, decrypt, getID } from "../../UtilHelper";
import Button from "../Elements/Button/Button";

const MobileDrawer = ({toggleDrawer}) => {
  const [openMenu, setOpenMenu] = useState(null);
  const { context, setContext } = useContext(AppContext);
  const navigate = useNavigate();

  const toggleSubMenu = (index) => {
    setOpenMenu(openMenu === index ? null : index);
  };

  useEffect(() => {
    try {
      if (!!getID("userDetails")) {
        setContext((prev) => {
          return {
            ...prev,
            userDetails: decrypt(getID("userDetails")),
            isLoggedIn: true,
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleUserLogout = () => {
    try {
      setContext({});
      toggleDrawer()
      clearUserData();
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={Styles.drawerContainer}>
      <div className={Styles.menuListContainer}>

     
        <ul className={Styles.parentList}>
          {MENU_LIST.map((item, index) => (
            <li key={index} className={Styles.parentListOption}>
              <div
                className={`${Styles.menuHeader} ${
                  openMenu === index ? Styles.activeHeader : ""
                }`}
                onClick={() => toggleSubMenu(index)}
              >
                <span>{item.name}</span>
                {item.subMenuList?.length > 0 && (
                  <i
                    className={`fas fa-chevron-${
                      openMenu === index ? "up" : "down"
                    } ${Styles.icon}`}
                  ></i>
                )}
              </div>
              <div
                className={`${Styles.subMenuContainer} ${
                  openMenu === index ? Styles.showSubMenu : ""
                }`}
              >
                {item.subMenuList?.map((subItem, subIndex) => (
                  <Link
                    to={subItem.link}
                    key={subIndex}
                    className={Styles.subMenuLink}
                  >
                    {subItem.name}
                  </Link>
                ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={Styles.AuthContainer}>
        {!!!context?.isLoggedIn ? (
          ""
        ) : (
          <div className={Styles?.cart}>
            <Link to="/wishlist">
              <i class="far fa-heart"></i>
            </Link>
            <Link to="/cart">
              <i class="fas fa-cart-shopping"></i>
            </Link>
          </div>
        )}
        {!!!context?.isLoggedIn ? (
          <div className={Styles.loginSingupContainer}>
            <Button
              onClick={() =>{
                toggleDrawer()
                navigate("/login")}}
              label="Login"
              className={`${Styles?.loginbtn} `}
            />
            <Button
              onClick={() =>{ 
                toggleDrawer()
                navigate("/signup")}}
              label="Sign up"
              className={`${Styles?.signupbtn} `}
            />
          </div>
        ) : (
          <Button
            onClick={handleUserLogout}
            label="Logout"
            className={`${Styles?.signupbtn} `}
          />
        )}


      </div>
    </div>
  );
};

export default MobileDrawer;
