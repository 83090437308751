import { Link, useNavigate } from "react-router-dom";
import { APP_LOGO, MENU_LIST } from "../../Config/Config";
import Styles from "./Header.module.css";
import Input from "../Elements/Input/Input";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "../Elements/Button/Button";
import { clearUserData, decrypt, getID } from "../../UtilHelper";
import { AppContext } from "../Context/Context";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import MobileDrawer from "../MobileDrawer/MobileDrawer";

const Header = ({ token }) => {
  const [showSearch, setShowSearch] = useState(false);
  const divRef = useRef(null);
  const navigate = useNavigate();
  const { context, setContext } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleClickOutside = (event) => {
    try {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowSearch(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (!!getID("userDetails")) {
        setContext((prev) => {
          return {
            ...prev,
            userDetails: decrypt(getID("userDetails")),
            isLoggedIn: true,
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleUserLogout = () => {
    try {
      setContext({});
      clearUserData();
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  };

  const renderSubMenuList = (subMenuList) => {
    return (
      <p className={Styles.subMenuList}>
        {subMenuList.map((subItem, index) => (
          <p key={index} className={Styles.subLink}>
            <Link to={subItem.link}>
              <p>
                {subItem.name}
                {subItem.subMenu?.length > 0 && (
                  <i className="fas fa-caret-right"></i>
                )}
              </p>
              {subItem.subMenu?.length > 0 &&
                renderSubMenuList(subItem.subMenu)}
            </Link>
          </p>
        ))}
      </p>
    );
  };

  const renderSubMenu = (subMenuList) => {
    return (
      <p className={Styles.subMenu}>
        {subMenuList.map((subItem, index) => (
          <p key={index} className={Styles.subLink}>
            <Link to={subItem.link}>
              <p>
                {subItem.name}
                {/* {subItem.subMenuList?.length > 0 && (
                  <i className="fas fa-caret-right"></i>
                )} */}
              </p>
              {subItem.subMenu?.length > 0 &&
                renderSubMenuList(subItem.subMenu)}
            </Link>
          </p>
        ))}
      </p>
    );
  };

  return (
    <div className={Styles?.navbarContainer}>
      <div className={Styles?.logoContainer}>
        <Link to="/">
          <img src={APP_LOGO} alt="Business Optima" />
        </Link>
      </div>
      <div className={Styles.menuListContainer}>
        <ol className={Styles.parentList}>
          {MENU_LIST.map((item, index) => (
            <p key={index} className={Styles.parentListOption}>
              <Link to={item.link}>
                <p>
                  {item.name} {item.subMenuList?.length > 0 &&  <i class="fa-solid fa-caret-down"></i>}
                </p>
              </Link>
              {item.subMenuList?.length > 0 && renderSubMenu(item.subMenuList)}
            </p>
          ))}
          <p className={Styles.parentListOption}>
            {showSearch && (
              <Input
                ref={divRef}
                className={`${Styles.searchInput} fadeInUp`}
                placeHolder="Search"
              />
            )}
            <i
              onClick={() => setShowSearch(!showSearch)}
              className="fas fa-magnifying-glass"
            ></i>
          </p>
        </ol>
      </div>
      <div className={Styles.searchContainer}>
        {!!!context?.isLoggedIn ? (
          ""
        ) : (
          <div className={Styles?.cart}>
            <Link to="/wishlist">
              <i class="far fa-heart"></i>
            </Link>
            <Link to="/cart">
              <i class="fas fa-cart-shopping"></i>
            </Link>
          </div>
        )}
        {!!!context?.isLoggedIn ? (
          <div className={Styles.loginSingupContainer}>
            <Button
              onClick={() => navigate("/login")}
              label="Login"
              className={`${Styles?.loginbtn} `}
            />
            <Button
              onClick={() => navigate("/signup")}
              label="Sign up"
              className={`${Styles?.signupbtn} `}
            />
          </div>
        ) : (
          <Button
            onClick={handleUserLogout}
            label="Logout"
            className={`${Styles?.signupbtn} `}
          />
        )}


      </div>
      <div className={Styles.drawerContainer}>
        <div className={Styles.drawerButtonCotainer}>
          {/* {!!!context?.isLoggedIn &&  <Button
              onClick={() => navigate("/signup")}
              label="Sign up"
              className={`${Styles?.signupbtn} `}
            />} */}
          <button
            onClick={toggleDrawer}
            className={Styles.drawerOpenerButton}
            aria-label="Open menu"
          >
            <i className={`fas fa-bars ${Styles.drawerOpenerIcon}`}></i>
          </button>
        </div>


        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction='right'
          className='bla bla bla'
        >
          <MobileDrawer toggleDrawer={toggleDrawer} />
        </Drawer>
      </div>

    </div>
  );
};

export default Header;
