import React from "react";
import Styles from "./Departments.module.css";
import { DEPARTMENTCARDS } from "../../../Config/Config";
import Card from "../Card/Card";

const Departments = () => {
  return (
    <div className={`${Styles?.Container}`}>
      <h1>Our Departments</h1>
      <div className={Styles?.cardcontainer}>
      {DEPARTMENTCARDS.map((card, index) => (
        <Card key={index} image={card.icon} title={card.title} imageClass={Styles?.cardImage}/>
      ))}
    
      </div>
     
    </div>
  );
};

export default Departments;