import { CARDS } from "../../../Config/Config";
import React from "react";
import Card from "../Card/Card";
import Styles from "./CardContainer.module.css";


const CardContainer = () => {
  return (
    <div className={Styles?.cardcontainer}>
      {CARDS.map((card, index) => (
        <Card key={index} image={card.icon} title={card.title} iconclass={Styles?.icon}/>
      ))}
    </div>
  );
};

export default CardContainer;
